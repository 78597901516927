<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :show-operations="false"
      no-meta-check
      :buttons="computedButtons"
      :resource-query="resourceQuery"
      :custom-resource="customResource"
      resource="stocks"
      route-base="/reports/statistics/stock"
      :headers-default-hide="headersDefaultHide"
      show-filter
      do-route-filters
      :locale="locale"
      prune-search-filters
      :search-filters="filtersList"
      :row-key="(item) => `${item.product}-${item.location}`"
      headers-filterable
      :headers-config="headersConfig"
      @headers-config="handleHeadersConfig"
      @row-click="handleRowClick"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import Decimal from 'decimal.js-light'
import { mapGetters } from 'vuex'
import qs from 'qs'
import mapValues from 'just-map-values'
import isEmpty from 'just-is-empty'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import { useExportsStore } from '@/store/exports'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.stocks.stocks')
    }
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.stock'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      filters: {},
      width: 500,
      locations: [],
      customResource: th.analyticsHandlers().analytics.reports
        .AnalyticsReportsStocks,
      headersDefaultHide: [
        'product_group',
        'supplier',
        'manufacturer',
        'brand'
      ],
      headers: [
        {
          label: this.$t('pages.reports.statistics.stocks.product_number'),
          field: 'product_custom_id',
          sortable: true,
          minWidth: 155,
          truncate: true,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.product_name'),
          field: 'product_name',
          sortable: true,
          truncate: true,
          minWidth: 200,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.product_group'),
          field: 'product_group',
          sortable: true,
          truncate: true,
          minWidth: 200,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.product_ean'),
          field: 'product_barcode',
          sortable: true,
          minWidth: 155,
          truncate: true,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.qty'),
          field: 'qty_available',
          sortable: true,
          minWidth: 180,
          truncate: true,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('common.headers.location.title'),
          field: 'location',
          sortable: true,
          minWidth: 200,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row.location) return '-'

            const location = this.locations.find(
              (item) => item && item.id === row.location
            )
            if (location) {
              const title =
                location.type === 'warehouse'
                  ? this.$t(
                      'pages.reports.statistics.stocks.location_types.warehouse.label'
                    )
                  : this.$t(
                      'pages.reports.statistics.stocks.location_types.branch.label'
                    )
              return `${title}: ${this.$formatBranch(location) || '-'}`
            }
            return '-'
          }
        },
        {
          label: this.$t(
            'pages.reports.statistics.stocks.default_purchase_price'
          ),
          field: 'default_purchase_price',
          sortable: true,
          minWidth: 200,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (!Number.isFinite(row.default_purchase_price)) return '-'
            return this.$formatCurrency(
              row.default_purchase_price,
              row.currency
            )
          },
          sortType: 'currency'
        },
        {
          label: this.$t(
            'pages.reports.statistics.stocks.default_selling_price'
          ),
          field: 'default_selling_price',
          sortable: true,
          minWidth: 200,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (!Number.isFinite(row.default_selling_price)) return '-'
            return this.$formatCurrency(row.default_selling_price, row.currency)
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.supplier'),
          field: 'supplier',
          sortable: true,
          truncate: true,
          align: 'right',
          minWidth: 200,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.manufacturer'),
          field: 'manufacturer',
          sortable: true,
          truncate: true,
          align: 'right',
          minWidth: 200,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.stocks.brand'),
          field: 'brand',
          sortable: true,
          truncate: true,
          align: 'right',
          minWidth: 200,
          fallback: '-'
        }
      ],
      revenueOptions: [
        {
          label: this.$t('pages.reports.statistics.products.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.net_revenue'),
          value: 'net_revenue',
          type: 'currency'
        }
      ],
      summary: null,
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:stocks:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: () => {
            this.handleExport()
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      branchNumber: 'Config/getCurrentBranchNumber',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currencies: 'Config/getAvailableCurrencies',
      currentLocation: 'Config/getCurrentLocation',
      locale: 'Config/getLocale'
    }),
    filtersList() {
      const currencyFilter =
        this.currencies.length > 1
          ? [
              {
                name: 'currency',
                type: 'currency-select',
                currency: this.currentCurrency,
                options: this.currencies,
                label: this.$t('pages.reports.statistics.stocks.currency')
              }
            ]
          : []
      return [
        {
          name: 'product_custom_id',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.stocks.product_number'),
          placeholder: this.$t('common.inputs.placeholders.search'),
          resource: 'products',
          optionsValue: 'custom_id',
          computedFields: ['custom_id', 'name'],
          fetchHandler: 'getAll',
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            original_product: true
          })
        },
        {
          name: 'product_barcode',
          type: 'input',
          label: this.$t('pages.reports.statistics.stocks.product_ean')
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        ...currencyFilter
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    currentCurrency() {
      return this.parsedQuery.currency
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    resourceQuery() {
      return {
        branch_custom_id: this.branchNumber || undefined,
        product_custom_id: this.parsedQuery.product_custom_id || undefined,
        product_barcode: this.parsedQuery.product_barcode || undefined,
        branch_group: this.parsedQuery.branch_group || undefined,
        currency: this.parsedQuery.currency || undefined
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  created() {
    this.fetchLocations()
  },
  mounted() {
    if (isEmpty(this.$route.query)) {
      this.makeQuery({
        branch_custom_id: this.branchNumber,
        currency: this.defaultCurrency
      })
    }

    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    getCellValue(header, row) {
      const cellValue = safeGet(row, header.value)
      const type = header.type
      if (header.value === 'sales_rep') {
        return this.$formatStaff(row) || '--'
      } else if (cellValue === null) {
        return this.$t('pages.reports.statistics.customers.null')
      } else if (type === 'number') {
        const d = new Decimal(cellValue)
        return d.toDecimalPlaces(4).toString()
      } else {
        return cellValue
      }
    },
    async handleExport() {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...this.parsedQuery,
        format: 'csv'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlers()
          .analytics.reports.AnalyticsReportsStocks.export({ query })

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.stocks.stocks',
            date: new Date(),
            action: {
              entity: 'analyticsHandlers',
              path: 'analytics.reports.AnalyticsReportsStocks',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.stocks.stocks')
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    },
    makeQuery(v) {
      const q = { ...this.parsedQuery, ...v }

      const filter = mapValues(q, (value, key) => {
        if (value === null || value === undefined) return undefined
        return value
      })

      const route = this.$makeFilteredPath(this.$route.path, filter)
      this.$safePush(route)
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'products-edit',
        params: { id: row.product }
      })
    },
    async fetchLocations() {
      try {
        this.locations = await this.$resourceFetch({
          resource: 'locations',
          handler: () => th.stocks().getLocations({ query: { deleted: false } })
        }).then(({ locations }) => locations)
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    }
  }
}
</script>
